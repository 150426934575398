
import Card from "@/components/base/card/Card"
import Button from "@/components/base/Button"
import { mdiClose } from "@mdi/js"
import { mapGetters } from "vuex"

export default {
    name: "EmptyRatingCard",
    emits: ["close"],
    components: {
        Card,
        Button,
    },
    icons: {
        mdiClose,
    },
    computed: {
        ...mapGetters("routing", ["previousSearchRoute"]),
    },
}
